import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBWloAMeVnPcXYoUPGux8bOsZxWJJQvsu0",
    authDomain: "devsinghindraportfolio.firebaseapp.com",
    databaseURL: "https://devsinghindraportfolio.firebaseio.com",
    projectId: "devsinghindraportfolio",
    storageBucket: "devsinghindraportfolio.appspot.com",
    messagingSenderId: "1060978098939",
    appId: "1:1060978098939:web:57673465c54a1fffcc665e"
  };

  firebase.initializeApp(firebaseConfig);

  export default firebase;